<template>
  <b-row>
    <b-col>
      <b-card>
        <div class="d-flex align-items-center justify-content-between">
          <div class="truncate d-flex flex-column">
            <h4 class="mb-25 font-weight-bolder">
              {{ dkStatistics.count_do }}
            </h4>

            <span class="fs-13">Количество ДО в работе</span>
          </div>
          <b-avatar
            variant="light-primary"
            size="45"
          >
            <feather-icon
              size="21"
              icon="ArchiveIcon"
            />
          </b-avatar>
        </div>
      </b-card>
    </b-col>

    <b-col>
      <b-card>
        <div class="d-flex align-items-center justify-content-between">
          <div class="truncate d-flex flex-column">
            <h4 class="mb-25 font-weight-bolder">
              {{ $_moneyFormatter(dkStatistics.summa_do) }}
            </h4>

            <span class="fs-13">Сумма ДО в работе</span>
          </div>
          <b-avatar
            variant="light-primary"
            size="45"
          >
            <feather-icon
              size="21"
              icon="HeadphonesIcon"
            />
          </b-avatar>
        </div>
      </b-card>
    </b-col>

    <b-col>
      <b-card>
        <div class="d-flex align-items-center justify-content-between">
          <div class="truncate d-flex flex-column">
            <h4 class="mb-25 font-weight-bolder">
              {{ dkStatistics.call_count }}
            </h4>

            <span class="fs-13">Количество звонков</span>
          </div>
          <b-avatar
            variant="light-primary"
            size="45"
          >
            <feather-icon
              size="21"
              icon="HeadphonesIcon"
            />
          </b-avatar>
        </div>
      </b-card>
    </b-col>

    <b-col>
      <b-card>
        <div class="d-flex align-items-center justify-content-between">
          <div class="truncate d-flex flex-column">
            <h4 class="mb-25 font-weight-bolder">
              {{ dkStatistics.promise_count }}
            </h4>

            <span class="fs-13">Обещания об оплате</span>
          </div>
          <b-avatar
            variant="light-primary"
            size="45"
          >
            <feather-icon
              size="21"
              icon="HeadphonesIcon"
            />
          </b-avatar>
        </div>
      </b-card>
    </b-col>

    <b-col>
      <b-card>
        <div class="d-flex align-items-center justify-content-between">
          <div class="truncate d-flex flex-column">
            <h4 class="mb-25 font-weight-bolder">
              {{ dkStatistics.finished_promise_count }}
            </h4>

            <span class="fs-13">Выполненные обещания</span>
          </div>
          <b-avatar
            variant="light-primary"
            size="45"
          >
            <feather-icon
              size="21"
              icon="HeadphonesIcon"
            />
          </b-avatar>
        </div>
      </b-card>
    </b-col>

    <b-col>
      <b-card>
        <div class="d-flex align-items-center justify-content-between">
          <div class="truncate d-flex flex-column">
            <h4 class="mb-25 font-weight-bolder">
              {{ dkStatistics.closed_debt }}
            </h4>

            <span class="fs-13">Взыскано</span>
          </div>
          <b-avatar
            variant="light-primary"
            size="45"
          >
            <feather-icon
              size="21"
              icon="HeadphonesIcon"
            />
          </b-avatar>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
} from 'bootstrap-vue'
import { mapState } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
  },
  computed: {
    ...mapState('dkLegalFace', ['dkStatistics']),
  },
}
</script>
<style>
.truncate {
  span {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
  }
}
</style>
