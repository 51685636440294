<template>
  <div>
    <statistics />

    <b-overlay
      :show="loader"
      rounded="sm"
    >
      <b-row>
        <b-col class="col-md-2">
          <BFormGroup
            label="Поиск"
          >
            <BFormInput
              v-model="filter.search"
              type="search"
            />
          </BFormGroup>
        </b-col>

        <b-col class="col-md-3 text-right ml-auto">
          <b-button
            style="margin-top: 27px"
            variant="primary"
            @click="detailCollector"
          >
            <feather-icon
              icon="PlayIcon"
            />
            Старт
          </b-button>
        </b-col>
      </b-row>
      <b-card class="mt-1">
        <b-table
          :fields="fields"
          :items="dkList.results"
          show-empty
          responsive
          @row-clicked="onRowSelected"
        >
          <template #empty>
            <empty />
          </template>
          <template #cell(EMAIL)="props">
            <!--            <span-->
            <!--              class="text-primary"-->
            <!--              v-html=" props.item.EMAIL"-->
            <!--            />-->

            <div
              v-for="(item, index) in props.item.EMAIL"
              :key="index"
              @click.stop
            >
              <a
                :href="'mailto:' + item"
                class="ml-50 text-right"
                v-html="item"
              />
            </div>
          </template>

          <template #cell(FULL_NAME)="data">
            <div
              v-if="data.item.FULL_NAME"
              class="text-primary cursor-pointer"
            >
              {{ data.item.FULL_NAME }}
            </div>
          </template>
        </b-table>
      </b-card>

      <b-card>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0">
            <span class="text-nowrap mr-1">Показать по</span>
            <b-form-select
              v-model="filter.page_size"
              :options="['5', '10', '20']"
              class="mx-1"
              @change="refresh"
            />
            <span class="ml-1 text-nowrap"> строк( {{ dkList.count }} )</span>
          </div>
          <div>
            <b-pagination
              v-model="filter.page"
              :total-rows="dkList.count"
              :per-page="filter.page_size"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="changePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BButton,
  BTable,
  BCard,
  BFormSelect,
  BPagination,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BOverlay,
  BSpinner,
} from 'bootstrap-vue'
import empty from '@/components/empty.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import statistics from './statistics.vue'

export default {
  components: {
    statistics,
    empty,
    BButton,
    BTable,
    BCard,
    BFormSelect,
    BPagination,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BOverlay,
    BSpinner,
  },
  data() {
    return {
      loader: false,
      playBtn: false,
      filter: {
        search: null,
        page: 1,
        page_size: 20,
      },
      fields: [
        {
          key: 'id',
          label: '№',
          sortable: false,
        },
        {
          key: 'FULL_NAME',
          label: 'Названия',
          sortable: false,
        },
        {
          key: 'EMAIL',
          label: 'Электронная почта',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('dkLegalFace', ['dkList', 'currentId']),
  },
  watch: {
    'filter.search': {
      handler() {
        clearTimeout(this.time)
        this.time = setTimeout(() => {
          this.filter.page = 1
          this.refresh()
        }, 500)
      },
    },
  },
  mounted() {
    this.refresh()
  },
  methods: {
    ...mapMutations('legalPortfoliosCollector', ['SET_ACTIVE', 'SET_CALL']),
    ...mapActions('dkLegalFace', ['FETCH_DK_LIST', 'FETCH_CURRENT_ID', 'FETCH_DK_STATISTICS']),

    async refresh() {
      try {
        this.loader = true
        this.FETCH_DK_STATISTICS(this.filter)
        await this.FETCH_DK_LIST(this.filter)
      } catch (e) {
        this.loader = false
        await this.$_errorToast(e)
      }
      this.loader = false
    },

    async onRowSelected(params) {
      try {
        this.loader = true
        await this.FETCH_CURRENT_ID()
          .then(() => {
            this.SET_CALL(false)
            this.SET_ACTIVE(3)

            this.$router.push({
              name: 'legal-collector-id',
              query: { play: 'true' },
              params: {
                id: params.id,
              },
            })
            this.loader = false
          })
      } catch (e) {
        this.loader = false
        await this.$_errorToast(e)
      }
    },

    async detailCollector() {
      try {
        this.loader = true
        await this.FETCH_CURRENT_ID()
          .then(() => {
            this.SET_CALL(false)
            this.SET_ACTIVE(3)

            this.$router.push({
              name: 'legal-collector-id',
              query: { play: 'true' },
              params: {
                id: this.currentId.current_id,
              },
            })
            this.loader = false
          })
      } catch (e) {
        this.loader = false
        if (e && e.message === 'Request failed with status code 404') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Нет активных дела',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          }, {
            position: 'bottom-right',
          })
        } else {
          await this.$_errorToast(e)
        }
      }
    },

    changePage(page) {
      this.filter.page = page
      this.refresh()
    },

    DO_STAGE_TEXT(value) {
      const message = {
        voluntary_payment: 'Добровольная оплата',
        claim: 'Претензия',
        judical_work: 'Судебная работа',
        executive_document: 'Исполнительный документ',
        executive_work: 'Исполнительное производство',
        stopped: 'Приостановлено',
        paid: 'Погашен',
      }

      return message[value] || ''
    },
  },
}
</script>
